export const Testimonials = (props) => {
  return (
  <div id='testimonials'>
    <div className='container'>
        <div className='row'>
          <div className='section-title text-center'>
            <h2>Get Involved</h2>
            <p>
              Complete our survey to have your voice heard and play an active role in shaping the design and development of our project.
            </p>
            <a class="btn btn-custom btn-lg btn-block active" href="#" role="button">Answer the Survey</a>
          </div>
        </div>
      </div>
    </div>
  )
}
