export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>
            Experts in AI, community engagement, and EV integration.
          </p>
        </div>
        <div id='row-eq-height'>
          {props.data
            ? Array.from({ length: Math.ceil(props.data.length / 4) }, (_, rowIndex) => (
              <div key={`row-${rowIndex}`} className='row'>
                {props.data.slice(rowIndex * 4, (rowIndex + 1) * 4).map((d, colIndex) => (
                  <div key={`${d.name}-${rowIndex * 4 + colIndex}`} className='col-md-3 col-sm-3 partners'>
                    <div className='thumbnail'>
                      {' '}
                      <img src={d.img} alt='...' className='team-img' />
                      <div className='caption'>
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          : 'loading'}
        </div>
      </div>
    </div>
  )
}
