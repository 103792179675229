export const Partners = (props) => {
  return (
    <div id='partners' className='text-center'>
      <div className='container'>
        <div className='col-md-12 col-md-offset-0 section-title'>
          <h2>Key Partners</h2>
          {/* <p>
            Experts in AI, community engagement, and EV integration.
          </p> */}
        </div>
        <div id='row-eq-height'>
        {props.data
          ? Array.from({ length: Math.ceil(props.data.length / 3) }, (_, rowIndex) => (
              <div key={`row-${rowIndex}`} className='row'>
                {props.data.slice(rowIndex * 3, (rowIndex + 1) * 3).map((d, colIndex) => (
                  <div key={`${d.name}-${rowIndex * 3 + colIndex}`} className='col-md-4 col-sm-4 partners'>
                    <div className='thumbnail'>
                      <img src={d.img} alt='...' className='partners-img' />
                      <div className='caption'>
                        <h4><a href={d.url} target="_blank" rel="noreferrer">{d.name}</a></h4>
                        <p>{d.blurb}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          : 'loading'}
      </div>
      </div>
    </div>
  )
}
